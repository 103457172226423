<template>
  <v-container
      id="panels"
      fluid
      tag="section"
  >
    <v-row no-gutters>
      <v-col cols="12" md="12">

        <base-material-card
            color="success"
            icon="mdi-card-account-details"
            :title="baseMaterialCardTitle"
            inline
        >
          <v-row>
            <v-col cols="10"></v-col>
            <v-col cols="2" class="text-right">
              <v-btn small right color="info" class="mr-0"
                     @click="$router.push({name: 'Users', params: {envCode: envSelected.value} })">
                <v-icon small class="mr-1">
                  mdi-arrow-left
                </v-icon>
                Back
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="user" no-gutters>
            <v-col cols="12" md="12">

              <template v-if="!user">
                <v-progress-linear
                    indeterminate
                    color="primary"
                ></v-progress-linear>
                <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
              </template>
              <v-tabs v-else right>

                <v-tab href="#profile">
                  <v-icon left>mdi-account</v-icon>
                  General
                </v-tab>
                <v-tab href="#security">
                  <v-icon left>mdi-lock</v-icon>
                  Security
                </v-tab>
                <v-tab href="#multitenancy">
                  <v-icon left>mdi-apps</v-icon>
                  Multi Tenancy
                </v-tab>

                <v-tab-item class="mt-5" id="profile">
                  <v-card class="mt-0">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                              label="First Name"
                              v-model="user.firstName"
                              prepend-icon="mdi-account"
                          />
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                              label="Last Name"
                              v-model="user.lastName"
                              prepend-icon="mdi-account"
                          />
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                              :disabled="!fieldAccess.username"
                              label="Username"
                              v-model="user.username"
                              prepend-icon="mdi-account-details"
                          />
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                              :disabled="!fieldAccess.email"
                              label="Email"
                              type="email"
                              v-model="user.email"
                              prepend-icon="mdi-mail"
                          />
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-select
                              :items="arw"
                              :disabled="!fieldAccess.auto_registration_flow"
                              v-model="user.auto_registration_flow"
                              item-text="name"
                              item-value="name"
                              label="Auto Registration Flow"
                              hint="Select the Auto Registration Flow"
                              persistent-hint
                              prepend-icon="mdi-domain"
                          ></v-select>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                              label="Company"
                              v-model="organization.name"
                              prepend-icon="mdi-domain"
                              disabled
                          />
                        </v-col>

                        <v-col cols="12" md="12">
                          <v-btn v-if="fieldAccess.password"
                                 small
                                 color="secondary"
                                 @click="changePassword"
                          >
                            <v-icon small class="mr-1">mdi-lock</v-icon>
                            Change password
                          </v-btn>
                        </v-col>
                      </v-row>

                      <change-user-password ref="changeUserPassword" :env-selected="envSelected"></change-user-password>

                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item class="mt-5" id="security">
                  <user-account-security
                      :fieldAccess="fieldAccess"
                      :groups="groups"
                      :strategies="strategies"
                      :context="user"
                      :env-selected="envSelected"
                      @updateThrottlingStrategies="updateThrottlingStrategies"
                  ></user-account-security>
                </v-tab-item>
                <v-tab-item class="mt-5" id="multitenancy">
                  <user-account-multitenancy
                      v-if="showTenants"
                      :fieldAccess="fieldAccess"
                      :section="'user'"
                      :context="user"
                      :env-selected="envSelected"
                      :tenants="tenants"
                      @updateTenants="updateTenants"
                  ></user-account-multitenancy>
                </v-tab-item>

              </v-tabs>
            </v-col>
          </v-row>
          <v-row v-if="groups && groups.length > 0" no-gutters>
            <v-col cols="12" md="12" class="text-right">
              <v-btn v-if="canAccess({route: '/organization/users/:id', method: 'patch'})"
                     color="success" class="text-right" @click="updateUser">
                <v-icon class="mr-1">mdi-content-save</v-icon>
                Save
              </v-btn>
              <v-btn color="error" class="ml-2 text-right" @click="reset">
                <v-icon class="mr-1">mdi-undo</v-icon>
                Reset
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-else-if="!loading" class="mt-5">
            <v-alert type="warning" outlined border="left" class="mt-5 mx-auto py-3" prominent>
              <h2>Oops!</h2>
              <p>
                It appears you have no Groups Created.<br/>
                Therefore you cannot create any User Accounts.
              </p>
              <p>
                Head to the <b>Groups</b> Module from the top right User Menu.<br/>
                Create some groups, then come back to this section and you will be able to create user accounts.
              </p>
              <v-btn color="secondary" class="mx-auto float-left" small elevation="3" @click="openDocumentation">
                <v-icon small color="white" class="mr-1">mdi-information</v-icon>
                Read the Docks
              </v-btn>
              <v-btn color="primary" class="mx-auto float-right" small elevation="3" @click="goToGroups">
                <v-icon small color="white" class="mr-1">mdi-account-group</v-icon>
                open Groups
              </v-btn>
            </v-alert>
          </v-row>
        </base-material-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import ChangeUserPassword from "./components/changeUserPassword";
import usersMixins from "./components/mixins";
import fieldsMixins from "./components/fieldAccess";
import globalMixins from "../../../../mixins/globalMixins";
import UserAccountSecurity from "@/views/internal/pages/users/components/security";
import UserAccountMultitenancy from "@/views/internal/pages/users/components/multitenancy";

export default {
  name: 'Account',

  mixins: [globalMixins, usersMixins, fieldsMixins],

  data: () => ({
    fieldAccess: {},
    organization: null,
    user: null,
    groups: [],
    strategies: [],
    tenants: [],
    showTenants: false,
    loading: true
  }),

  components: {
    UserAccountMultitenancy,
    UserAccountSecurity,
    ChangeUserPassword
  },

  props: {
    envSelected: {
      type: Object
    },
    id: {
      type: String
    }
  },

  async created() {
    this.initialize();
  },

  computed: {

    baseMaterialCardTitle() {
      let label = 'Creating new';
      if (this.id) {
        label = "Updating";
      }
      if (this.user) {
        return `${label} User ${this.user.firstName ? `'${this.user.firstName} ${this.user.lastName}'` : ''}`;
      } else {
        return '';
      }
    }
  },

  methods: {

    goToGroups() {
      this.$router.push({name: 'Groups', params: {envSelected: this.envSelected}})
    },

    openDocumentation() {
      window.open(this.$helpLinks.users);
    },

    reset() {
      if (confirm("Are you sure you want to reset the page? (All your changes will be lost)")) {
        this.initialize();
      }
    },

    async initialize() {
      this.loading = true;
      this.user = null;
      this.organization = null;
      this.groups = [];
      this.strategies = [];
      this.tenants = [];

      this.fieldAccess = this.userUpdateAccess();

      const tenants = await this.getSendData({
        'url': `/consoleapi/environments/${this.envSelected.value}/default/tenants`,
        'method': 'get',
        params: {
          noEnv: true
        }
      });

      const groups = await this.getSendData({
        'url': '/consoleapi/organization/groups',
        'method': 'get'
      });

      const user = await this.getSendData({
        'url': `/consoleapi/organization/users/${this.id}`,
        'method': 'get'
      });

      const organization = await this.getSendData({
        'url': `/consoleapi/organization/accounts/${user.item.account}`,
        'method': 'get'
      });

      this.organization = organization.item;

      const arw = await this.getSendData({
        'url': `/consoleapi/environments/${this.envSelected.value}/arw/custom-entries`,
        'method': 'get'
      });

      this.arw = arw.items || [];

      this.user = user.item;
      this.groups = groups.items;
      this.tenantsAll = tenants.items;

      this.renderData();
    },

    renderTenants() {
      const _self = this;
      _self.tenants = this._lodash.cloneDeep(_self.tenantsAll);

      let userTenants = [];
      if (_self.organization.tenants) {
        userTenants = _self._lodash.cloneDeep(_self.organization.tenants);
        userTenants.forEach(function (one) {
          one.locked = true;
        });
      }
      const uniqTIds = _self._lodash.uniq(userTenants.map(t => t.id));
      if (_self.user.tenants && _self.user.tenants.length) {
        _self.user.tenants.forEach(function (one) {
          if (!uniqTIds.includes(one.id)) {
            userTenants.push(one);
          }
        });
      }
      _self.user.tenants = _self._lodash.cloneDeep(userTenants);

      for (let i = _self.tenants.length - 1; i >= 0; i--) {
        let found = false;
        if (_self.user && _self.user.tenants) {
          _self.user.tenants.forEach((userTenant) => {
            if (userTenant.code === _self.tenants[i].code
                && userTenant.id === _self.tenants[i].id) {
              userTenant.name = _self.tenants[i].name;
              userTenant.env = _self.tenants[i].env;
              userTenant.description = _self.tenants[i].description;
              found = true;
            }
          });
        }
        if (found) {
          _self.tenants.splice(i, 1);
        }
      }

      for (let i = _self.user.tenants.length - 1; i >= 0; i--) {
        if (!_self.user.tenants[i].env && !_self.user.tenants[i].name) {
          _self.user.tenants.splice(i, 1);
        }
      }
      this.showTenants = true;
      this.$forceUpdate();
    },

    renderData() {
      const _self = this;

      function renderSecurity() {
        if (!_self.user.security) {
          _self.user.security = {};
        }
        if (Object.keys(_self.user.security).length === 0) {
          if (_self.organization.security) {
            _self.user.security = _self._lodash.cloneDeep(_self.organization.security);
          }
        }

        if (!_self.user.security.throttling) {
          _self.user.security.throttling = {};
        }
        if (!_self.user.security.throttling.strategies) {
          _self.user.security.throttling.strategies = [];
        }

        if (_self.user.security && _self.user.security.throttling && _self.user.security.throttling) {
          for (let i in _self.user.security.throttling) {
            if (!['publicAPIStrategy', 'privateAPIStrategy', 'strategies'].includes(i)) {
              let strategy = _self.user.security.throttling[i];
              strategy.name = i;
              _self.user.security.throttling.strategies.push(strategy);
              delete _self.user.security.throttling[i];
            }
          }
          _self.strategies = _self._lodash.cloneDeep(_self.user.security.throttling.strategies);
        }
      }

      function renderGroups() {
        if (!_self.user.groups || _self.user.groups.length === 0) {
          _self.user.groups = [];
          if (_self.organization.groups) {
            _self.user.groups = _self._lodash.cloneDeep(_self.organization.groups);
          }
        } else {
          let userGroups = _self._lodash.concat(_self.user.groups, _self.organization.groups);
          _self.user.groups = _self._lodash.cloneDeep(userGroups);
        }
      }

      renderSecurity();
      renderGroups();
      _self.renderTenants();

      this.loading = false;
    },

    changePassword() {
      if (Object.hasOwnProperty.call(this.$refs.changeUserPassword, 'changeUserPassword')) {
        this.$refs.changeUserPassword.changeUserPassword(this.user);
      }
    },

    updateThrottlingStrategies(newList) {
      this.user.security.throttling.strategies = newList;
    },

    updateTenants(item, mode) {
      const self = this;
      let apiOptions = {
        url: `/consoleapi/organization/users/${this.id}/tenants/${mode}`,
        method: "patch",
        params: {
          tenants: [
            {
              id: item.id,
              code: item.code
            }
          ]
        }
      };

      self.getSendData(apiOptions)
          .then(() => {
            self.showTenants = false;

            self.getSendData({
              'url': `/consoleapi/organization/users/${this.id}`,
              'method': 'get'
            }).then((user) => {
              if (user.item) {
                self.user.tenants = user.item.tenants;
              }
              self.$nextTick(() => {
                self.renderTenants();
              });
            });

          });
    },

    updateUser() {
      const self = this;
      //validate the payload before invoking the API
      if (!this.validateUserRecord(this.user)) {
        return false;
      }

      let newUser = this.cleanUpUserOrganization(this.user, this.organization);
      delete newUser.config;
      delete newUser.tenants;
      delete newUser.status;

      this.filterFields(newUser, this.fieldsAcl.user.update);

      let apiOptions = {
        url: `/consoleapi/organization/users/${this.id}`,
        method: "patch",
        params: {...newUser}
      };

      self.getSendData(apiOptions)
          .then((response) => {
            self.pushMessage({
              type: 'success',
              title: `User updated`,
              text: `User Information, Security and Multitenancy have been updated!.`
            });

            setTimeout(() => {
              this.initialize();
            }, 2000);

          });
    },

  }
}
</script>

<style scoped>
.routerLink {
  text-decoration: none;
}

</style>