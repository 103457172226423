<template>
  <v-row>
    <v-col
        cols="6"
        md="6"
        xs="12"
    >
      <base-material-card
          color="success"
          inline
          text="Linked Tenants"
      >

        <v-data-table
            :loading="loading"
            :headers="headers"
            :items="context.tenants"
            item-key="priority"
            disable-pagination
            hide-default-footer
        >
          <template v-slot:item.env="{item}">
            {{ item.env }}
          </template>

          <template v-slot:item.name="{item}">
            {{ item.name }}
          </template>

          <template v-slot:item.actions="{ item }">
            <template v-if="context.id">
              <v-tooltip v-if="item.soft" top>
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                      v-on="on"
                      v-bind="attrs"
                      class="ml-2"
                      x-small
                      fab
                      color="info"
                  >
                    <v-icon small>mdi-information</v-icon>
                  </v-btn>
                </template>
                <span>You need to Save your changes first, then you can configure this service.</span>
              </v-tooltip>
              <v-tooltip v-else-if="!item.soft && item.env.toLowerCase()===envSelected.value" top>
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                      v-on="on"
                      v-bind="attrs"
                      class="ml-2"
                      x-small
                      fab
                      color="success"
                      @click="goBackToParent(item)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editing Tenant Configuration</span>
              </v-tooltip>
              <v-tooltip v-else-if="!item.soft && (item.env.toLowerCase()!==envSelected.value)" top>
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                      v-on="on"
                      v-bind="attrs"
                      class="ml-2"
                      x-small
                      fab
                      color="orange"
                  >
                    <v-icon small>mdi-information</v-icon>
                  </v-btn>
                </template>
                <span>
                  You can only edit the config once you switch to the corresponding environment.
                </span>
              </v-tooltip>
            </template>

            <v-tooltip v-if="fieldAccess.tenants" top>
              <template v-slot:activator="{on, attrs}">
                <v-btn v-if="!item.locked"
                       v-on="on"
                       v-bind="attrs"
                       class="ml-2"
                       x-small
                       fab
                       color="error"
                       @click="deleteTenant(item)"
                >
                  <v-icon small>mdi-link-off</v-icon>
                </v-btn>
              </template>
              <span> Unlink Tenant </span>
            </v-tooltip>
          </template>
        </v-data-table>
      </base-material-card>
    </v-col>

    <v-col
        cols="6"
        md="6"
        xs="12"
    >
      <base-material-card
          color="success"
          inline
          text="Available Tenants"
      >

        <v-data-table
            :loading="loading"
            :headers="headers"
            :items="multitenancy.data"
            disable-pagination
            hide-default-footer
        >
          <template v-slot:item.env="{item}">
            {{ item.env }}
          </template>

          <template v-slot:item.name="{item}">
            {{ item.name }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip top v-if="fieldAccess.tenants">
              <template v-slot:activator="{on, attrs}">
                <v-btn
                    v-on="on"
                    v-bind="attrs"
                    fab
                    x-small
                    @click="linkTenant(item)"
                    color="secondary"
                >
                  <v-icon small>mdi-link</v-icon>
                </v-btn>
              </template>
              <span>Link Tenant</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </base-material-card>
    </v-col>

  </v-row>
</template>
<script>

import globalMixins from "@/mixins/globalMixins";

export default {
  name: 'userAccountMultitenancy',

  data: () => ({
    loading: true,
    headers: [
      {
        text: 'ENV',
        value: 'env',
        width: '20%'
      },
      {
        text: 'Name',
        value: 'name',
      },
      {
        align: 'right',
        text: 'Actions',
        value: 'actions',
      },
    ],
    multitenancy: {
      data: []
    },
  }),

  mixins: [globalMixins],

  props: {
    mode: {type: String},
    fieldAccess: {type: Object},
    section: {type: String},
    context: {type: Object},
    envSelected: {type: Object},
    tenants: {type: Array},
  },

  created() {
    this.initialize();
  },

  methods: {

    initialize() {
      this.loading = true;
      this.multitenancy.data = [...this.tenants];
      this.loading = false;
      if (!Object.hasOwnProperty(this.fieldAccess, 'tenants')) {
        this.fieldAccess.tenants = true;
      }
    },

    deleteTenant(item) {
      if (this.mode === 'create') {
        let mtData = [...this.multitenancy.data];
        this.multitenancy.data = [];
        mtData.push(item);
        this.multitenancy.data = mtData;

        let contextTenants = [...this.context.tenants];
        this.context.tenants = [];
        for (let i = contextTenants.length - 1; i >= 0; i--) {
          if ((contextTenants[i].code === item.code) && contextTenants[i].id === item.id) {
            contextTenants.splice(i, 1);
          }
        }
        this.context.tenants = contextTenants;

      } else {
        if (confirm(`Are you sure you want to delete: ${item.code}? All configuration for this application will also be removed. This step is irreversible`)) {
          this.$emit('updateTenants', item, 'unlink');
        }
      }

    },

    linkTenant(item) {
      if (this.mode === 'create') {
        let newTenant = {...item};
        newTenant.soft = true;
        let contextTenants = [...this.context.tenants];
        this.context.tenants = [];
        contextTenants.push(newTenant);
        this.context.tenants = contextTenants;

        let mtData = [...this.multitenancy.data];
        this.multitenancy.data = [];
        for (let i = mtData.length - 1; i >= 0; i--) {
          if (mtData[i].code === newTenant.code && (mtData[i].id === newTenant.id)) {
            mtData.splice(i, 1);
          }
        }
        this.multitenancy.data = mtData;
      } else {
        if (confirm(`Are you sure you want to Link: ${item.code}`)) {
          this.$emit('updateTenants', item, 'link');
        }
      }

    },

    goBackToParent(item) {
      let routeParams = {
        route: 'editTenantConfig',
        params: {
          id: item.id,
          code: item.code
        }
      };

      switch (this.section) {
        case 'user':
          routeParams.params['user'] = this.context.id;
          routeParams.route = "editUserTenantConfig";
          break;
        case 'account':
          routeParams.params['account'] = this.context.id;
          routeParams.route = "editAccountTenantConfig";
          break;
      }

      this.goToPage(routeParams);
    }
  }
}
</script>