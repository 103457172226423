<template>
  <div>
    <v-card class="mt-0">
      <template v-if="!security.groups || security.groups.length === 0">
        <v-progress-linear
            indeterminate
            color="primary"
        ></v-progress-linear>
        <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
      </template>
      <template v-else>
        <v-card-title>Groups
          <help-circle :context="help.groups"></help-circle>
        </v-card-title>
        <v-divider class="mt-2"></v-divider>
        <v-row class="mr-1 ml-1">
          <v-col cols="12">
            <v-select
                :disabled="!fieldAccess.groups"
                v-model="context.groups"
                :items="security.groups"
                label="Groups"
                multiple
                chips
                clearable
                deletable-chips
                hint="Select which Groups the user is part of."
                persistent-hint
                hide-selected
                item-value="code"
                item-text="code"
            >
              <template v-slot:selection="{attrs, item, parent, selected}">
                <!-- HTML that describe how select should render selected items -->
                <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    color="primary">
                  <span>{{ item.name }}</span>
                  <v-icon small @click="parent.selectItem(item)">mdi-close</v-icon>
                </v-chip>
              </template>

              <template v-slot:item="data">
                <!-- HTML that describe how select should render items when the select is open -->
                <strong class="mr-3">{{ data.item.name }}</strong>(<i class="pl-2 pr-2">{{
                  data.item.description
                }}</i>)
              </template>

              <template v-slot:append-item>
                <v-divider class="mb-2"></v-divider>
                <v-list-item disabled>
                  <v-list-item-avatar color="grey lighten-3">
                    <v-icon>mdi-account-multiple</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>Users can be allocated to multiple groups.</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </template>
    </v-card>
    <throttling
        v-if="fieldAccess.security"
        ref="throttlingWrapper"
        :env-selected="envSelected"
        :strategies="strategies"
        :context="context"
        @updateThrottlingStrategies="updateThrottlingStrategies"
    ></throttling>
  </div>
</template>
<script>
import throttling   from "@/views/internal/components/custom/throttling";
import globalMixins from "@/mixins/globalMixins";
import HelpCircle   from "@/views/internal/components/custom/helpCircle";

export default {

  name: 'userAccountSecurity',

  mixins: [ globalMixins ],

  components: {
    HelpCircle,
    throttling
  },

  data: () => ( {
    security: {
      groups: []
    },
    help: {
      groups: {
        right: true,
        text: `User Security Group`,
        link: 'groups'
      }
    }
  } ),

  props: {
    fieldAccess: { type: Object },
    envSelected: { type: Object },
    context: { type: Object },
    groups: { type: Array },
    strategies: { type: Array }
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      if (!Object.hasOwnProperty(this.fieldAccess, 'groups')){
        this.fieldAccess.groups = true;
      }
      if (!Object.hasOwnProperty(this.fieldAccess, 'security')){
        this.fieldAccess.security = true;
      }

      this.security.groups = [ ...this.groups ];
      // force render throttling component
      this.$nextTick(() => {
        if ( Object.hasOwnProperty.call( this.$refs.throttlingWrapper, 'initialize' ) ) {
          this.$refs.throttlingWrapper.initialize();
        }
      } );
    },

    updateThrottlingStrategies( newList ) {
      this.$emit( 'updateThrottlingStrategies', newList );
    }
  }

};
</script>